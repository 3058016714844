<main class="container_main">
    <header class="header_main">
        <img class="logo_wunderman_thompson" src="assets/images/svg/wunderman-thompson.svg" alt="Wunderman thompson Colombia">
    </header>
    <div class="rectangle"></div>

    <section class="s1 plus_2">
        <div class="growth">
            <div class="container">
                <h1 class="title">
                    Growth for Clients
                </h1>
                <h6 class="subtitle">
                    Sesiones estratégicas de transformación.
                </h6>
                <hr class="separator">
                <p class="description">
                    Nuestro objetivo es ampliar, alinear y/o unificar conocimiento en las áreas más relevantes de transformación digital para el negocio de <span class="highlight">CNCH</span>.
                </p>
            </div>
            <video
                class="home_video"
                muted="muted"
                autoplay="true"
                loop="true"
                webkit-playsinline="true"
                playsinline="true"
                #homeVideo
            >
                <source src="assets/video/1.ogv">
                <source src="assets/video/1.webm">
                <source src="assets/video/1.mp4">
            </video>


            <div class="scroll_explore">
                <p class="text"> Scroll to explore </p>
                <a href="#object" class="button_scroll icon_scroll">Ir abajo</a>
                <hr class="separator">
            </div>
        </div>
        <span class="decoration"></span>
    </section>

    <section class="s2 plus" id="object">
        <div class="container">
            <h1 class="objects">
                3 Objetivos:
            </h1>
            <div class="container_objects">
                <div class="group_object">
                    <h2 class="title blue">
                        Comunicación <br>
                        de precisión
                    </h2>
                    <h3 class="subtitle">Branding + Digital</h3>
                    <p>
                        Cuando asignamos objetivos de mercadeo, solemos asignarle al canal digital el performance. Pero, ¿Cómo construimos valor de marca en el canal digital?
                    </p>
                    <h3 class="subtitle">CX Shopper</h3>
                    <p>
                        Los journeys ya no están basados en los puntos de contacto con la marca, están basados en la experiencia del consumidor alrededor de la marca.
                        Cómo establecer la mejor experiencia para mi usuario?
                    </p>
                    <h3 class="subtitle"> Ecosistema digital</h3>
                    <p>
                        Cómo definir el más acertado para mi marca y cuáles son los roles que debe cumplir cada uno.
                        Definición buyer personas.
                        Establecimiento de KPIs.
                    </p>
                    <h3 class="subtitle">Social media</h3>
                    <p>
                        Cómo crear contenido basado en afinidades y no en producto.
                    </p>
                    <h3 class="subtitle">Posicionamiento orgánico</h3>
                    <p>
                        Cuáles son las variables y best practices para estar mejor rankeados en buscadores.
                        <br>
                        CGA
                        <br>
                        FCP
                    </p>
                </div>
                <div class="group_object">
                    <h2 class="title magenta">Commerce</h2>
                    <h3 class="subtitle">El nuevo retail</h3>
                    <ul>
                        <li>Contexto/Experiencia</li>
                        <li>Precise Floor Data</li>
                        <li>Product as Content</li>
                        <li>People Driven Retail</li>
                        <li>Real Time Offer</li>
                        <li>Foldable Experiencies</li>
                        <li>Ventas por whatsapp </li>
                        <li>Franquicias virtuales</li>
                    </ul>
                    <br><br>
                    <p>
                        ISC Back to Business:
                    </p>
                    <p>
                        Cuáles son las reglas básicas, cómo hacer sentir seguro al consumidor
                        <br>
                        Bioseguridad
                        <br>
                        Vitrinas UVC
                    </p>
                    <br><br>
                    <p>
                        Live Shopping: Rappi+Clout / Plataformas personalizadas
                    </p>
                    <h3 class="subtitle">E-Tailing</h3>
                    <p>
                        Cómo definir el norte de la estrategia de ventas online y qué aliados se hacen indispensables.
                    </p>
                </div>
                <div class="group_object">
                    <h2 class="title green">MEDICIÓN</h2>
                    <h3 class="subtitle">Performance</h3>
                    <p>
                        Cómo atraer audiencia calificada con objetivos de conversión (consideración y trial).
                    </p>
                    <h3 class="subtitle">Crawler</h3>
                    <p>
                        Qué hacer para mejorar el performance en los e-tailers? Cómo ayudarse de un bot que se comporta como un humano,
                        encontrando cómo productos se encuentran bien posicionados, dónde están mal optimizados y quién gana en la categoría.
                    </p>
                    <h3 class="subtitle">CRM</h3>
                    <p>
                        La estrategia de atracción debe estar combinada con una estrategia de retención,
                        porque cada vez debemos depender menos de los medios pagos y construir y profundizar en nuestra base de datos propia.
                    </p>
                    <h3 class="subtitle">Seguimiento KPIs</h3>
                    <p>
                        Cómo consolidar y visualizar los objetivos trazados en KPIs de seguimiento que permitan tomar decisiones en tiempo real.
                    </p>
                </div>
            </div>
            <div class="graphic">
                <hr class="separator">
                <img class="cycle" src="assets/images/svg/graphic.svg" alt="Estrategia CNCH">
            </div>
        </div>

    </section>

    <section class="s3">
        <div class="container">
            <h1 class="title">¿Cómo lo haremos?</h1>
            <p class="description">
                Elegiremos una marca, trabajaremos sesiones teóricas y prácticas sobre esa marca y al final entenderemos cómo se consolida una estrategia digital.
            </p>

            <hr class="separator">

            <h3 class="speaker title_1">Speakers</h3>
            <ul class="speakers_list">
                <li class="speaker">
                    <img class="speakers_img" src="assets/images/people/daniel_payan.jpg" alt="Daniel Payan">
                    <p class="speaker_name">Daniel Payan</p>
                </li>
                <li class="speaker">
                    <img class="speakers_img" src="assets/images/people/felipe_sanchez.jpg" alt="Felipe Sánchez">
                    <p class="speaker_name">Felipe Sánchez</p>
                </li>
                <li class="speaker">
                    <img class="speakers_img" src="assets/images/people/ana_cuenca.jpg" alt="Ana Cuenca">
                    <p class="speaker_name">Ana Cuenca</p>
                </li>
                <li class="speaker">
                    <img class="speakers_img" src="assets/images/people/stefanie_parra.jpg" alt="Stefanie Parra">
                    <p class="speaker_name">Stefanie Parra</p>
                </li>
                <li class="speaker">
                    <img class="speakers_img" src="assets/images/people/yurani_tamayo.jpg" alt="Yurani Tamayo">
                    <p class="speaker_name">Yurani Tamayo</p>
                </li>
                <li class="speaker">
                    <img class="speakers_img" src="assets/images/people/nicolas_diaz.jpg" alt="Nicolás Diaz">
                    <p class="speaker_name">Nicolás Diaz</p>
                </li>
                <li class="speaker">
                    <img class="speakers_img" src="assets/images/people/julian_lara.jpg" alt="Julián Lara">
                    <p class="speaker_name">Julián Lara</p>
                </li>
                <li class="speaker">
                    <img class="speakers_img" src="assets/images/people/ivan_moreno.jpg" alt="Ivan Moreno">
                    <p class="speaker_name">Ivan Moreno</p>
                </li>

                <li class="speaker">
                    <img class="speakers_img" src="assets/images/people/ruben_avila.jpg" alt="Ruben Ávila">
                    <p class="speaker_name">Ruben Ávila</p>
                </li>
            </ul>
            <br><br>

            <div class="container_group">
                <div class="group">
                    <h3 class="comunication title_1">Comunicación de Precisión</h3>

                    <div class="data_table">
                        <div class="title_data">
                            <h4 class="title">Sesiones</h4>
                        </div>
                        <div class="title_data">
                            <h4 class="title">Tiempo</h4>
                        </div>
                        <div class="title_data">
                            <h4 class="title">Speaker</h4>
                        </div>

                        <div class="content_data">
                            <p>Branding</p>
                            <p> + Digital</p>
                        </div>
                        <div class="content_data">
                            <p> 1 Hora Teórica </p>
                            <p> 2 Horas Prácticas </p>
                        </div>
                        <div class="content_data">
                            <p>Julián Lara</p>
                        </div>

                        <div class="content_data">
                            <p>CX Shopper</p>
                        </div>
                        <div class="content_data">
                            <p> 1 Hora Teórica </p>
                            <p> 1 Hora Práctica </p>
                        </div>
                        <div class="content_data">
                            <p>Ruben Avila</p>
                        </div>

                        <div class="content_data">
                            <p>Ecosistema digital</p>
                        </div>
                        <div class="content_data">
                            <p> 1 Hora Teórica </p>
                            <p> 1 Hora Práctica </p>
                        </div>
                        <div class="content_data">
                            <p>Julián Lara</p>
                        </div>

                        <div class="content_data">
                            <p>Social Media</p>
                        </div>
                        <div class="content_data">
                            <p> 1 Hora Teórica </p>
                            <p> 2 Horas Prácticas </p>
                        </div>
                        <div class="content_data">
                            <p>Felipe Sánchez</p>
                        </div>

                        <div class="content_data">
                            <p>Posicionamiento</p>
                            <p>Orgánico</p>
                        </div>
                        <div class="content_data">
                            <p> 1 Hora Teórica </p>
                            <p> 1 Hora Práctica </p>
                        </div>
                        <div class="content_data">
                            <p>Ivan Moreno</p>
                            <p>Ana Cuenca</p>
                        </div>
                    </div>

                    <hr class="separator">
                </div>

                <div class="group">
                    <h3 class="commerce title_1">Commerce</h3>
                    <div class="data_table">
                        <div class="title_data">
                            <h4 class="title">Sesiones</h4>
                        </div>
                        <div class="title_data">
                            <h4 class="title">Tiempo</h4>
                        </div>
                        <div class="title_data">
                            <h4 class="title">Speaker</h4>
                        </div>

                        <div class="content_data">
                            <p>El nuevo retail</p>
                        </div>
                        <div class="content_data">
                            <p>1 Hora Teórica</p>
                            <p>2 Horas Prácticas</p>
                        </div>
                        <div class="content_data">
                            <p>Daniel Payan</p>
                            <p>Felipe Sánchez</p>
                        </div>

                        <div class="content_data">
                            <p>E-tailing</p>
                        </div>
                        <div class="content_data">
                            <p>1 Hora Teórica</p>
                            <p>1 Hora Práctica</p>
                        </div>
                        <div class="content_data">
                            <p>Stefanie Parra</p>
                        </div>

                        <div class="content_data">
                            <p>Performance</p>
                        </div>
                        <div class="content_data">
                            <p>1 Hora Teórica</p>
                            <p>1 Hora Práctica</p>
                        </div>
                        <div class="content_data">
                            <p>Yurani Tamayo</p>
                        </div>

                        <div class="content_data">
                            <p>Crawler</p>
                        </div>
                        <div class="content_data">
                            <p>1 Hora Teórica</p>
                            <p>2 Horas Prácticas</p>
                        </div>
                        <div class="content_data">
                            <p>Yurani Tamayo</p>
                        </div>
                    </div>

                    <hr class="separator">
                </div>

                <div class="group">
                    <h3 class="conversion title_1">Conversión y Medición</h3>

                    <div class="data_table">
                        <div class="title_data">
                            <h4 class="title">Sesiones</h4>
                        </div>
                        <div class="title_data">
                            <h4 class="title">Tiempo</h4>
                        </div>
                        <div class="title_data">
                            <h4 class="title">Speaker</h4>
                        </div>

                        <div class="content_data">
                            <p>CRM</p>
                        </div>
                        <div class="content_data">
                            <p>1 Hora Teórica</p>
                            <p>2 Horas Prácticas</p>
                        </div>
                        <div class="content_data">
                            <p>Ana Cuenca</p>
                        </div>

                        <div class="content_data">
                            <p>Seguimientos de KPIS</p>
                        </div>
                        <div class="content_data">
                            <p>1 Hora Teórica</p>
                            <p>1 Hora Práctica</p>
                        </div>
                        <div class="content_data">
                            <p>Yurani Tamayo</p>
                        </div>

                    </div>

                    <hr class="separator">
                </div>
            </div>

            <span class="decoration_2"> </span>
        </div>
    </section>

    <footer class="footer_main">
        <p class="text">We Inspire Growth</p>
        <a class="link_wunderman_thompson" href="https://www.wundermanthompson.com/colombia">wundermanthompson.com/colombia</a>
        <p class="copyright">@WundermanThompsonCo</p>
    </footer>
</main>
