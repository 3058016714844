import { Component, AfterViewInit, ViewChild, ElementRef, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements AfterViewInit, OnInit {
    @ViewChild("homeVideo") videoplayer: ElementRef;

    private deviceInfo = null;

    public isDesktop:Boolean;

    constructor(private deviceService: DeviceDetectorService) {}

    ngAfterViewInit(): void {
        if (this.isDesktop) {
            let video:HTMLVideoElement = this.videoplayer.nativeElement;

            video.muted = true;
            video.play();
        }
    }

    ngOnInit(): void {
        this.deviceInfo = this.deviceService.getDeviceInfo();
        this.isDesktop = this.deviceService.isDesktop();
    }
}
