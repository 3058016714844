import { Component, AfterViewInit } from '@angular/core';
import Utils from "@shared/helper/utils";

const CLASSBODY = "nofound_page";

@Component({
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss']
})
export class NotfoundComponent implements AfterViewInit {
    ngAfterViewInit () {
        document.body.classList.add(CLASSBODY);
    }
}
